/**
 * Regex configuration
 */
/**
 * Regular expression patterns for various validations.
 */
export class Regex {
  /**
   * Regular expression pattern for decimal numbers.
   */
  static readonly decimal = /^\d+$/;

  /**
   * Regular expression pattern for email validation.
   */
  static readonly email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/;
  static readonly emailCart =
    /^(?!.*\.\.)(?!.*\.$)(?![.-])[a-zA-Z0-9._%+-]+@[a-zA-Z0-9][a-zA-Z0-9-]*\.[a-zA-Z]{2,}\s*$/;

  /**
   * Regular expression pattern for special characters.
   */
  static readonly special = /[\_\=\!\@\+\-\[\]\.\,\/\'\"\;\:\~\`\|\\\#\$\%\^\&\*\(\)\,\.\?\"\:\{\}\|\<\>]/;

  /**
   * Regular expression pattern for password validation.
   */

  static readonly password = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).*$/;

  /**
   * Regular expression pattern for white space validation.
   */
  static readonly whiteSpace = /^.*[^ ].*$/;

  /**
   * Regular expression pattern for URL validation.
   */
  static readonly url =
    /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/i;

  /**
   * Regular expression pattern for integer numbers.
   */
  static readonly integer = /^[0-9]*$/;

  /**
   * Regular expression pattern for positive integer numbers.
   */
  static readonly positiveInteger = /^\+?([1-9]\d*)$/;

  /**
   * Regular expression pattern for min-max validation.
   */
  static readonly min_max: RegExp = /\.\{\d+,\d+}/;

  /**
   * Regular expression pattern for image file extensions.
   */
  static readonly fileImage: RegExp = /^(.*)\.(jpg|jpeg|png|bmp|gif)$/i; // config.ts ACCEPTED_IMAGES_EXTENSIONS

  /**
   * Regular expression pattern for document file extensions.
   */
  static readonly fileDocument: RegExp = /^(.*)\.(docx|doc|pdf|xml|bmp|ppt|xls)$/i;

  /**
   * Regular expression pattern for video file extensions.
   */
  static readonly fileVideo: RegExp = /^(.*)\.(mp4|mov|avi|wmv|mkv|flv)$/i;

  /**
   * Regular expression pattern for banned file extensions.
   */
  static readonly fileBanned: RegExp = /^(.*)\.(exe|mcs|sh|zip|7z|gz|rar|tar|bz|bz2|php|js|bat)$/i; // config.ts FORBIDDEN_BENCHMARKS_EXTENSIONS

  /**
   * Regular expression pattern for uppercase characters.
   */
  static readonly upperCaseCharacter: RegExp = /[A-Z]+/;

  /**
   * Regular expression pattern for digits only.
   */
  static readonly digitsOnly: RegExp = /[0-9]+/;

  /**
   * Regular expression pattern to exclude digits.
   */
  static readonly excludeDigits: RegExp = /[^0-9]+/gi;

  /**
   * Regular expression pattern for letters only.
   */
  static readonly lettersOnly: RegExp = /^[A-Za-zĄĆĘŁŃÓŚŹŻ]*$/i;

  /**
   * Regular expression pattern for validating house numbers.
   * It matches a string that starts with one or more digits, followed by an optional letter.
   * Example: 123, 456A, 789b, etc.
   */
  static readonly houseNumber: RegExp = /^\d+[A-Za-z]?$/i;

  /**
   * Regular expression pattern for validating building numbers.
   * The pattern allows for digits followed by an optional letter.
   * Example: 123, 456A, 789b, etc.
   */
  static readonly buildingNumber: RegExp = /^\d+[A-Za-z]?$/i;

  /**
   * Regular expression pattern for name validation.
   */
  static readonly nameRegex: RegExp = /^[A-Za-zÀ-ÿ\s\-AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż]*$/i;

  /**
   * Regular expression pattern for special characters.
   */
  static readonly specialCharacters: RegExp = /(?=.*\W)/i;

  /**
   * Regular expression pattern for phone number validation.
   */
  static readonly phoneNumber: RegExp = /^\s*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3,6})(?: *x(\d+))?\s*$/;

  /**
   * Regular expression pattern to check if a URL has "https" protocol.
   */
  static readonly hasHttps: RegExp = /^(?:f|ht)tps?\:\/\//;

  /**
   * Regular expression pattern for detecting new lines.
   */
  static readonly newLine: RegExp = /(?:\r\n|\r|\n)/g;

  /**
   * Regular expression pattern for website validation.
   */
  static readonly website = /^(?!https?:\/\/)(?!www\.)[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/;


  static readonly dateDay: RegExp = /^(\d{1,2})$/;
  static readonly dateDayWithMonth: RegExp = /^(\d{1,2})\/(\d{1,2})$/;
  static readonly fullDate: RegExp = /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/;
  static readonly fullDateWithHour: RegExp = /^(\d{1,2})\/(\d{1,2})\/(\d{2,4}) (\d{1,2})$/;
  static readonly fullDateWithTime: RegExp = /^(\d{1,2})\/(\d{1,2})\/(\d{2,4}) (\d{1,2}):(\d{1,2})$/;
  static readonly onlyHour: RegExp = /^(\d{1,2})$/;
  static readonly time: RegExp = /^(\d{1,2}):(\d{1,2})$/;
}
